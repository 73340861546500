import Vue from 'vue'
import NProgress from 'nprogress'
import axios from '@/plugins/axios'

const state = {
  agentList: [],
  agentCount: null
}

const getters = {
  agentList: state => {
    return state.agentList
  },
  agentMap: state => {
    return state.agentList.reduce(function(acc, cur) {
      acc[cur.entity_id] = cur;
      return acc;
    }, {});
  },
  agentCount: state => state.agentCount
}

const actions = {
  async getAgents(context, payload) {
    context.commit('SET_AGENT_LIST', [])
    let url = '/agents'
    if (payload && payload.securitysnaresDashboard) {
      url = `/securitysnares/agents`
    }
    NProgress.start();
    return axios.get(url).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_AGENT_LIST', resData.data)
      }
    })
  },
  async updateAgent(context, payload) {
    NProgress.start()
    context.commit('UPSERT_AGENT', payload)
    try {
      const { data } = await axios.put(`/agents`, payload);
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return true;
    } catch(e) {
      console.log(e);
    }
  },
  async getAgentCount(context) {
    NProgress.start()
    try {
      const { data } = await axios.get(`/securitysnares/agents/count`);
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.commit('SET_AGENT_COUNT', data.data.total)
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return true;
    } catch(e) {
      console.log(e);
    }
  }
}

const mutations = {
  SET_AGENT_LIST(state, data) {
    state.agentList = data
    NProgress.done()
  },
  SET_AGENT_COUNT(state, data) {
    state.agentCount = data
    NProgress.done()
  },
  UPSERT_AGENT(state, data) {
    let updateIdx = state.agentList.findIndex(agent => agent.entity_id == data.entity_id)
    if (updateIdx >= 0) {
      data.last_poll = state.agentList[updateIdx].last_poll
      Vue.set(state.agentList, updateIdx, data)
    } else {
      state.agentList.unshift(data)
    }
  },
  UPDATE_POLL(state, data) {
    let updateIdx = state.agentList.findIndex(agent => agent.entity_id == data.entity_id)
    if (updateIdx >= 0) {
      let agentObj = state.agentList[updateIdx]
      agentObj.last_poll = data.timestamp
      Vue.set(state.agentList, updateIdx, agentObj)
    } else {
      state.agentList.unshift({
        entity_id: data.agent_id,
        last_poll: data.timestamp
      })
    }
  },
  SET_SEEN_ALL_CRASH_LOGS(state, agentId) {
    if (agentId) {
      let updateIdx = state.agentList.findIndex(agent => agent.entity_id == agentId)
      state.agentList[updateIdx].unseen_crash_logs = 0
    } else {
      state.agentList = state.agentList.map(agent => {
        return {
          ...agent,
          unseen_crash_logs: 0
        }
      })
    }
  },
  UPDATE_NEW_CRASH_LOG(state, agentId) {
    let updateIdx = state.agentList.findIndex(agent => agent.entity_id == agentId)
    state.agentList[updateIdx].unseen_crash_logs = (state.agentList[updateIdx].unseen_crash_logs || 0) + 1
  },
  SET_SEEN_ALL_SUSPICIOUS_PROCESS(state, agentId) {
    if (agentId) {
      let updateIdx = state.agentList.findIndex(agent => agent.entity_id == agentId)
      state.agentList[updateIdx].unseen_suspicious_processes = 0
    } else {
      state.agentList = state.agentList.map(agent => {
        return {
          ...agent,
          unseen_suspicious_processes: 0
        }
      })
    }
  },
  UPDATE_NEW_SUSPICIOUS_PROCESS(state, agentId) {
    let updateIdx = state.agentList.findIndex(agent => agent.entity_id == agentId)
    state.agentList[updateIdx].unseen_suspicious_processes = (state.agentList[updateIdx].unseen_suspicious_processes || 0) + 1
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
