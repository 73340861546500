import NProgress from 'nprogress'
import axios from '@/plugins/axios'

const state = {
  suspiciousProcessLoading: false,
  suspiciousProcessList: [],
  agentId: null
}

const getters = {
  suspiciousProcessList: state => {
    return state.suspiciousProcessList
  },
  suspiciousProcessLoading: state => {
    return state.suspiciousProcessLoading
  }
}

const actions = {
  async getSuspiciousProcess(context, agentId) {
    NProgress.start();
    context.commit('SET_SUSPICIOUS_PROCESS_LIST', { data: [], agentId: null })
    let url = `/suspicious_process`
    if (agentId) {
      url += '/' + agentId
    }
    return axios.get(url).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_SUSPICIOUS_PROCESS_LIST', { data: resData.data, agentId: agentId })
        context.commit('SET_SEEN_ALL_SUSPICIOUS_PROCESS', agentId, { root: true })
      }
    })
  }
}

const mutations = {
  SET_SUSPICIOUS_PROCESS_LIST(state, { data, agentId }) {
    state.suspiciousProcessList = data
    state.agentId = agentId
    NProgress.done()
  },
  ADD_SUSPICIOUS_PROCESS(state, data) {
    if (state.agentId == data.agent_id || state.agentId == null) {
      state.suspiciousProcessList.unshift(data)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
