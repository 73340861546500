import NProgress from 'nprogress'
import axios from '@/plugins/axios'

const state = {
  wipersnareCrashLogsLoading: false,
  wipersnareCrashLogsList: [],
  wipersnareAgentId: null
}

const getters = {
  wipersnareCrashLogsList: state => {
    return state.wipersnareCrashLogsList
  },
  wipersnareCrashLogsLoading: state => {
    return state.wipersnareCrashLogsLoading
  }
}

const actions = {
  async getWipersnareCrashLogs(context, agentId) {
    NProgress.start();
    context.commit('SET_WIPERSNARE_CRASH_LOGS_LIST', { data: [], agentId: null })
    let url = `/wipersnare/crash_logs`
    if (agentId) {
      url += '/' + agentId
    }
    return axios.get(url).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_WIPERSNARE_CRASH_LOGS_LIST', { data: resData.data, agentId: agentId })
        context.commit('SET_SEEN_ALL_WIPERSNARE_CRASH_LOGS', agentId, { root: true })
      }
    })
  }
}

const mutations = {
  SET_WIPERSNARE_CRASH_LOGS_LIST(state, { data, agentId }) {
    state.wipersnareCrashLogsList = data
    state.wipersnareAgentId = agentId
    NProgress.done()
  },
  ADD_WIPERSNARE_CRASH_LOG(state, data) {
    if (state.wipersnareAgentId == data.agent_id || state.wipersnareAgentId == null) {
      state.wipersnareCrashLogsList.unshift(data)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
