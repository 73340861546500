import NProgress from 'nprogress'
import axios from '@/plugins/axios'

const state = {
  wipersnareSuspiciousProcessLoading: false,
  wipersnareSuspiciousProcessList: [],
  agentId: null
}

const getters = {
  wipersnareSuspiciousProcessList: state => {
    return state.wipersnareSuspiciousProcessList
  },
  wipersnareSuspiciousProcessLoading: state => {
    return state.wipersnareSuspiciousProcessLoading
  }
}

const actions = {
  async getwipersnareSuspiciousProcess(context, agentId) {
    NProgress.start();
    context.commit('SET_WIPERSNARE_SUSPICIOUS_PROCESS_LIST', { data: [], agentId: null })
    let url = `/wipersnare/suspicious_process`
    if (agentId) {
      url += '/' + agentId
    }
    return axios.get(url).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_WIPERSNARE_SUSPICIOUS_PROCESS_LIST', { data: resData.data, agentId: agentId })
        context.commit('SET_SEEN_ALL_WIPERSNARE_SUSPICIOUS_PROCESS', agentId, { root: true })
      }
    })
  }
}

const mutations = {
  SET_WIPERSNARE_SUSPICIOUS_PROCESS_LIST(state, { data, agentId }) {
    state.suspiciousProcessList = data
    state.agentId = agentId
    NProgress.done()
  },
  ADD_WIPERSNARE_SUSPICIOUS_PROCESS(state, data) {
    if (state.agentId == data.agent_id || state.agentId == null) {
      state.wipersnareSuspiciousProcessList.unshift(data)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
