import NProgress from 'nprogress';
import axios from '@/plugins/axios';
import { v4 as uuidv4 } from 'uuid';

const state = {
  apiKeyList: []
}

const getters = {
  apiKeyList: state => state.apiKeyList
}

const actions = {
  async getApiKeys(context) {
    NProgress.start();
    const organizationId = context.rootGetters.currentOrganization.entity_id;
    return axios.get(`/organization/${organizationId}/api_keys`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
      } else {
        context.commit('SET_API_KEY_LIST', resData.data);
      }
    })
  },
  async addApiKey(context, payload) {
    NProgress.start();
    let apiKey = Object.assign({}, payload);
    apiKey.tempId = 'ORG_'+uuidv4();
    context.commit('ADD_API_KEY', apiKey);
    const organizationId = context.rootGetters.currentOrganization.entity_id;
    try {
      const { data } = await axios.post(`/organization/${organizationId}/api_keys`, { 'label': payload.label, 'status': payload.status });
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      context.commit('UPDATE_ADDED_API_KEY', {org: data.data, tempId: apiKey.tempId});
      return true;
    } catch(e) {
      console.log(e);
    }
  },
  async updateApiKey(context, payload) {
    NProgress.start();
    context.commit('UPDATE_API_KEY', payload);
    try {
      const { data } = await axios.post(`/organization/${payload.organization_id}/api_keys`, { 'label': payload.label, 'status': payload.status, 'entity_id': payload.entity_id });
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return true;
    } catch(e) {
      console.log(e);
    }
  },
  async deleteApiKey(context, payload) {
    NProgress.start();
    context.commit('DELETE_API_KEY', payload);
    try {
      const { data } = await axios.delete(`/organization/${payload.organization_id}/api_keys`, { data: { 'entity_id': payload.entity_id } });
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return true;
    } catch(e) {
      console.log(e);
    }
  },
}

const mutations = {
  SET_API_KEY_LIST(state, data) {
    state.apiKeyList = data;
    NProgress.done();
  },
  ADD_API_KEY(state, data) {
    state.apiKeyList.push(data);
    NProgress.done();
  },
  UPDATE_API_KEY(state, data) {
    let index = state.apiKeyList.findIndex((e) => e.entity_id == data.entity_id);
    Object.assign(state.apiKeyList[index], data);
    NProgress.done();
  },
  UPDATE_ADDED_API_KEY(state, data) {
    let index = state.apiKeyList.findIndex((e) => e.tempId == data.tempId);
    state.apiKeyList.splice(index, 1, data.org);
    NProgress.done();
  },
  DELETE_API_KEY(state, payload) {
    let index = null;
    if(!payload.parentId) {
      index = state.apiKeyList.findIndex(e => e.entity_id == payload.entity_id);
      if(index > -1) {
        state.apiKeyList.splice(index, 1);
      }
    } else {
      let dataIndex = state.apiKeyList.findIndex(e => e.entity_id == payload.parentId);
      index = state.apiKeyList[dataIndex].children.findIndex(e => e.entity_id == payload.entity_id);
      if(index > -1) {
        state.apiKeyList[dataIndex].children.splice(index, 1);
      }
    }
    NProgress.done();
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
