var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":_vm.items,"label":_vm.label,"color":"#172950","menu-props":{ bottom: true, offsetY: true },"outlined":"","hide-details":"","dense":"","item-text":"name","item-value":"entity_id"},on:{"change":_vm.handleSelectedItem},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('v-spacer'),(item.is_managed)?_c('v-chip',{attrs:{"text-color":"black","small":""}},[(_vm.getOrganizationName(item.managed_by))?[_vm._v(" "+_vm._s(_vm.getOrganizationName(item.managed_by))+" ")]:[_vm._v(" Managed ")]],2):_vm._e()],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }