<template>
  <v-navigation-drawer
    width="100%"
    height="calc(100% - 2rem)"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-4 border-radius-xl"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <v-list-item class="pa-0 no-background">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0 justify-center justify-content-center">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center app-logo">
            <v-img
              :src="logoSrc"
              class="navbar-brand-img flex-1 me-2"
              width="180"
            >
            </v-img>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <hr
      class="horizontal mb-0"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <v-list nav dense>
      <v-list-group
        :ripple="false"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
        active-class="item-active"
      >
        <template v-slot:activator>
          <user-avatar-vue size="30" />
          <v-list-item-content>
            <v-list-item-title class="ms-2 ps-1 font-weight-light text-capitalize">
              {{ userDetails.first_name }}<span v-if="userDetails.last_name"> {{ userDetails.last_name}}</span>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover py-2"
          v-for="child in userInfo"
          :key="child.title"
        >
          <span
            class="v-list-item-mini ms-0 font-weight-light text-center w-20"
            v-text="child.prefix"
          ></span>

          <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
            <v-list-item-title v-text="child.title" @click="handleItemClick(child)"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-content class="ms-1 ps-1 py-0" v-if="child.items">
            <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="pa-0">
                  <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <v-list-item-content class="py-0">
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

              <v-list-item
                v-for="child2 in child.items"
                :ripple="false"
                :key="child2.title"
                :to="child2.link"
                @click="listClose($event)"
              >
                <span class="v-list-item-mini" v-text="child2.prefix"></span>
                <v-list-item-content>
                  <v-list-item-title v-text="child2.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <hr
      class="horizontal mb-3"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <v-list nav dense>
      <v-list-item-group>
        <div v-for="(item, i) in navItems" :key="i">
          <v-list-item
            link
            :to="item.link"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
            v-if="!item.external"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">{{ item.icon }}</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.title"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :href="item.link"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
            v-if="item.external"
            target="_blank"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">{{ item.icon }}</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.title"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>

    <v-card
      class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
    >
      <span
        class="mask opacity-8 border-radius-lg"
        :class="`bg-gradient-` + sidebarColor"
      ></span>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import UserAvatarVue from '@/components/Base/UserAvatar.vue';
import { mainNavItems, adminNavItems, msspNavItems, securitysnaresNavItems } from '../static/nav';
import commonMixin from '@/mixins/commonMixin'

export default {
  name: "drawer",
  mixins: [commonMixin],
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
  },
  components: { UserAvatarVue },
  computed: {
    userDetails() {
      return this.$store.getters['login/currentUser'];
    },
    navItems() {      
      return (this.isAdmin ? adminNavItems : this.isUserOfMssp ? msspNavItems : mainNavItems).concat(this.isSecuritySnaresEmployee ? securitysnaresNavItems : [])
    }
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    userInfo: [
      {
        title: "Logout",
        prefix: "L",
      },
    ],
    logoSrc: null,
  }),
  mounted() {
    this.updateLogo();
  },
  methods: {
    handleItemClick(item) {
      switch(item.title) {
        case "Logout":
          this.$store.dispatch('login/logout');
        break;
      }
    },
    async updateLogo() {
      const currentOrg = this.currentOrganization;

      if (currentOrg && (currentOrg.organization_type === 'mssp' || currentOrg.organization_type === 'client')) {
        if(currentOrg.organization_type === 'client' && !currentOrg.managed_by) {
          this.logoSrc = require("@/assets/ransomsnare-logo.png");
          return;
        }
        try {
          const logoData = await this.$store.dispatch('getOrganizationLogo');
          // Check if logoData is not null and has a 'file' property
          if (logoData && logoData.file && this.isPngImage(logoData.file)) {
            this.logoSrc = `${logoData.file}`;
          } else {
            // If logoData is null or doesn't have 'file', use the default logo
            this.logoSrc = require("@/assets/ransomsnare-logo.png");
          }
        } catch (error) {
          console.error(error);
          this.logoSrc = require("@/assets/ransomsnare-logo.png");
        }
      } else {
        // If entityId is not set, use the default logo
        this.logoSrc = require("@/assets/ransomsnare-logo.png");
      }
    },
    isPngImage(base64) {
      const pngHeader = "data:image/png;base64,";
      return base64.startsWith(pngHeader);
    },
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
  watch: {
    currentOrganization(newVal, oldVal) {
      if (newVal) {
        this.updateLogo();
      }
    },
  },
};
</script>
