import Vue from 'vue'
import NProgress from 'nprogress'
import axios from '@/plugins/axios'

const state = {
  wipersnareAgentList: []
}

const getters = {
  wipersnareAgentList: state => {
    return state.wipersnareAgentList
  },
  wipersnareAgentMap: state => {
    return state.wipersnareAgentList.reduce(function(acc, cur) {
      acc[cur.entity_id] = cur;
      return acc;
    }, {});
  }
}

const actions = {
  async getWipersnareAgents(context) {
    NProgress.start();
    return axios.get(`/wipersnare/agents`).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_WIPERSNARE_AGENT_LIST', resData.data)
      }
    })
  },
  async updateWipersnareAgent(context, payload) {
    NProgress.start()
    context.commit('UPSERT_WIPERSNARE_AGENT', payload)
    try {
      const { data } = await axios.put(`/wipersnare/agents`, payload);
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return true;
    } catch(e) {
      console.log(e);
    }
  },
}

const mutations = {
  SET_WIPERSNARE_AGENT_LIST(state, data) {
    state.wipersnareAgentList = data
    NProgress.done()
  },
  UPSERT_WIPERSNARE_AGENT(state, data) {
    let updateIdx = state.wipersnareAgentList.findIndex(agent => agent.entity_id == data.entity_id)
    if (updateIdx >= 0) {
      data.last_poll = state.wipersnareAgentList[updateIdx].last_poll
      Vue.set(state.wipersnareAgentList, updateIdx, data)
    } else {
      state.wipersnareAgentList.unshift(data)
    }
  },
  UPDATE_WIPERSNARE_POLL(state, data) {
    let updateIdx = state.wipersnareAgentList.findIndex(agent => agent.entity_id == data.entity_id)
    if (updateIdx >= 0) {
      let agentObj = state.wipersnareAgentList[updateIdx]
      agentObj.last_poll = data.timestamp
      Vue.set(state.wipersnareAgentList, updateIdx, agentObj)
    } else {
      state.wipersnareAgentList.unshift({
        entity_id: data.agent_id,
        last_poll: data.timestamp
      })
    }
  },
  SET_SEEN_ALL_WIPERSNARE_CRASH_LOGS(state, agentId) {
    if (agentId) {
      let updateIdx = state.wipersnareAgentList.findIndex(agent => agent.entity_id == agentId)
      state.wipersnareAgentList[updateIdx].unseen_crash_logs = 0
    } else {
      state.wipersnareAgentList = state.wipersnareAgentList.map(agent => {
        return {
          ...agent,
          unseen_crash_logs: 0
        }
      })
    }
  },
  UPDATE_NEW_WIPERSNARE_CRASH_LOG(state, agentId) {
    let updateIdx = state.wipersnareAgentList.findIndex(agent => agent.entity_id == agentId)
    state.wipersnareAgentList[updateIdx].unseen_crash_logs = (state.wipersnareAgentList[updateIdx].unseen_crash_logs || 0) + 1
  },
  SET_SEEN_ALL_WIPERSNARE_SUSPICIOUS_PROCESS(state, agentId) {
    if (agentId) {
      let updateIdx = state.wipersnareAgentList.findIndex(agent => agent.entity_id == agentId)
      state.wipersnareAgentList[updateIdx].unseen_suspicious_processes = 0
    } else {
      state.wipersnareAgentList = state.wipersnareAgentList.map(agent => {
        return {
          ...agent,
          unseen_suspicious_processes: 0
        }
      })
    }
  },
  UPDATE_NEW_WIPERSNARE_SUSPICIOUS_PROCESS(state, agentId) {
    let updateIdx = state.wipersnareAgentList.findIndex(agent => agent.entity_id == agentId)
    state.wipersnareAgentList[updateIdx].unseen_suspicious_processes = (state.agentList[updateIdx].unseen_suspicious_processes || 0) + 1
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
