import moment from "moment"
import {OrganizationType} from "../constants"

var adminEmailList = process.env.VUE_APP_ADMIN_EMAIL_LIST

var commonMixin = {
  computed: {
    currentUser () {
      return this.$store.getters['login/currentUser']
    },
    currentOrganization () {
      return this.$store.getters['currentOrganization']
    },
    isAdmin () {
      if(this.currentUser && adminEmailList) {
        let adminEmailListArr = adminEmailList.split(',')
        return adminEmailListArr.includes(this.currentUser.email)
      }
      return false
    },
    isImpersonatedAsSuperAdminOrg(){
      if(this.currentOrganization){
        return this.currentOrganization.organization_type == OrganizationType.SuperAdmin
      }
      return false;
    },
    isImpersonatedAsMSSPOrg(){
      if(this.currentOrganization){
        return this.currentOrganization.organization_type == OrganizationType.MSSP
      }
      return false;
    },
    
    isUserOfMssp(){
      if(this.currentUser.isUserOfMssp) return this.currentUser.isUserOfMssp
      var isUserOfMssp = false
      if(this.currentUser.organizations){
        isUserOfMssp = this.currentUser.organizations.some(org=>org.organization_type == OrganizationType.MSSP)
      }
      this.$store.commit('login/updateCurrentUserState', {key:'isUserOfMssp', value:isUserOfMssp})
      return isUserOfMssp
    },
    isSecuritySnaresEmployee () {
      return this.currentUser.is_securitysnares
    }
  },
  methods: {
    formatUnixDateTime(time) {
      return moment.unix(time).format('YYYY-MM-DD hh:mm:ss A')
    }
  }
}
export default commonMixin
