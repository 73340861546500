import NProgress from 'nprogress';
import axios from '@/plugins/axios';


const state = {
  logAlerts: [],
  loading: false,
  logAlertsDateFilter: null
}

const getters = {
  logAlerts: state => state.logAlerts,
  logAlertsDateFilter: state => state.logAlertsDateFilter,
  logAlertsLoading: state => state.loading
}

const actions = {
  async getLogAlerts(context, payload) {
    
    context.commit('SET_LOADING', true)
    context.commit('SET_LOG_ALERTS', [])
    payload?.sort((a, b) => new Date(a) - new Date(b));
    let query = payload ? {
        start: payload[0],
        end: payload[payload.length-1]
    } : {}
    // const organizationId = context.rootGetters.currentOrganization.entity_id;
    return axios.get(`/log_alerts`, {
        params: query
    }).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
      } else {
        context.commit('SET_LOG_ALERTS', resData.data);
      }
      context.commit('SET_LOADING', false)
    })
  }
}

const mutations = {
  SET_LOG_ALERTS(state, data) {
    state.logAlerts = data;
  },
  SET_LOADING(state, loading) {
    loading ? NProgress.start() : NProgress.done();
    state.loading = loading;
  },
  SET_DATE_FILTER(state, filter) {
    state.logAlertsDateFilter = filter;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
