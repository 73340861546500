import NProgress from 'nprogress'
import axios from '@/plugins/axios'

const state = {
  alerts: [],
  alertsCount: null,
  alertsLoading: false,
  selectedEntityIds:[]
}

const getters = {
  alerts: state => {
    return state.alerts
  },
  alertsCount: state => {
    return state.alertsCount
  },
  alertsLoading: state => {
    return state.alertsLoading
  },
  selectedEntityIds: state => {
    return state.selectedEntityIds
  }
}

const actions = {
  toggleAlertSelection({ commit, state }, entityId) {
    if (state.selectedEntityIds.includes(entityId)) {
      //log items in SelectedEntityIds
      commit('REMOVE_FROM_SELECTED', entityId);
    } else {
      //log items in SelectedEntityId
      commit('ADD_TO_SELECTED', entityId);
    }
  },
  async getAlerts(context, payload) {
    NProgress.start();
    context.commit('SET_LOADING', true)
    let url = `/alerts/paginated?`
    if (payload?.securitysnaresDashboard) {
      url = '/securitysnares' + url
    }
    if (payload.dismissed) {
      url += `dismissed=true&`
    }
    if (payload.collapsed) {
      url += `collapsed=true&`
    }
    if (payload.pageNo) {
      url += `page=${payload.pageNo}&`
    }
    if (payload.perPage) {
      url += `per_page=${payload.perPage}`
    }
    return axios.get(url).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_ALERT_LIST', {data: resData.data, page: payload.pageNo})
      }
      context.commit('SET_LOADING', false)
    })
  },
  async getAlertsCount(context, payload) {
    let url = `/alerts/count?`
    if (payload?.dismissed) {
      url += `dismissed=true&`
    }
    if (payload?.collapsed) {
      url += `collapsed=true&`
    }
    if (payload?.securitysnaresDashboard) {
      url = '/securitysnares' + url
    }
    return axios.get(url).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_ALERTS_COUNT', resData.data)
      }
    })
  },
  async getDismissedAlerts(context, payload) {
    NProgress.start();
    context.commit('SET_LOADING', true)
    let url = `/alerts?dismissed=true`
    if (payload?.securitysnaresDashboard) {
      url = '/securitysnares' + url
    }
    return axios.get(url).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_ALERT_LIST', resData.data)
      }
      context.commit('SET_LOADING', false)
    })
  },
  resetAlertsList(context) {
    context.commit('RESET_SELECTED')
  },
  async dismissAlert(context, payload) {
    NProgress.start();
    return axios.post(`/alerts/dismiss`, payload).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_ALERT_LIST', {
          data: context.state.alerts.filter((alert) => {
            return alert.entity_id != payload.entity_id
          })
        })
      }
    })
  },
  async dismissAlerts(context, payload) {
    NProgress.start();
    return axios.post(`/alerts/dismiss-multiple`, payload).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_ALERT_LIST', {
          data: context.state.alerts.filter((alert) => {
            return !payload.entity_ids.includes(alert.entity_id);
          })
        })
        context.commit('RESET_SELECTED')
      }
    })
  },
  //no longer used
  // async updateNotification(context, payload) {
  //   NProgress.start();
  //   return axios.put(`/alerts/update-notifications`, payload).then(function (response) {
  //     const resData = response.data
  //     if (!resData.success) {
  //       return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
  //     }
  //     else {
  //       context.dispatch('showMessage', { ...resData, messageType: 'success', messageTitle: 'Success' }, { root: true });
  //       NProgress.done()
  //     }
  //   })
  // },
  async updateAlertNotificationSettings(context, payload) {
    NProgress.start();
    return axios.put(`/organization/notification-preferences`, payload).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      }
      else {
        context.dispatch('showMessage', { ...resData, messageType: 'success', messageTitle: 'Success' }, { root: true });
        NProgress.done()
      }
    })
  }

}

const mutations = {
  RESET_ALERT_LIST(state) {
    state.alerts = []
  },
  SET_ALERT_LIST(state, data) {
    state.alerts = data.data
    NProgress.done()
  },
  SET_ALERTS_COUNT(state, data) {
    state.alertsCount = data.total
    NProgress.done()
  },
  SET_LOADING(state, isLoading) {
    state.alertsLoading = isLoading
  },
  ADD_TO_SELECTED(state, entityId) {
    if (!state.selectedEntityIds.includes(entityId)) {
      state.selectedEntityIds.push(entityId);
    }
  },
  REMOVE_FROM_SELECTED(state, entityId) {
    const index = state.selectedEntityIds.indexOf(entityId);
    if (index !== -1) {
      state.selectedEntityIds.splice(index, 1);
    }
  },
  RESET_SELECTED(state) {
    state.selectedEntityIds = [];
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
