<template>
  <v-app id="app">
    <notifications />
    <router-view />
  </v-app>
</template>

<script>
import localService from './services/localService';
import { checkValidity, LOGIN_CHECK_INTERVAL_MIN } from './router';
import commonMixin from '@/mixins/commonMixin'

export default {
  mixins: [commonMixin],
  data() {
    return {
      intervalId: null,
    }
  },
  methods: {
    checkIfUserIsLoggedIn() {
      const userInfo = localService.getItem('ransomsnare-userInfo');
      if (checkValidity()) {
        const loginType = localService.getItem('ransomsnare-loginType');
        if (loginType) {
          this.$store.commit('login/setState', { key: 'loginType', value: loginType });
        }
        this.$store.commit('login/setState', { key: 'currentUser', value: userInfo });
        this.$store.dispatch('getOrganizations').then(() => {
          this.$store.dispatch('loadCurrentOrganization');
          if (this.$router.currentRoute.path == '/') {
            if (this.isAdmin || this.isUserOfMssp) {
                this.$router.push('organizations')
            } else {
              this.$router.push('alerts')
            }
          }
        });
        return this.$store.commit('login/setState', { key: 'isLoggedIn', value: true });
      }
      return this.$store.dispatch('login/logout')
    }
  },
  mounted() { 
    this.checkIfUserIsLoggedIn();
  },
  watch: {
    '$store.state.login.isLoggedIn': function(val) {
      if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
      if (val) {
        this.intervalId = setInterval(this.checkIfUserIsLoggedIn, LOGIN_CHECK_INTERVAL_MIN*60*1000)
      }
    } 
  }
}
</script>


<style>
  /* SHARED STYLES */
  #app {
    font-family: 'Montserrat', sans-serif;
  }
  
  .absolute-loader {
    position: absolute;
  }
</style>