import NProgress from 'nprogress';
import axios from '@/plugins/axios';
import { v4 as uuidv4 } from 'uuid';

const state = {
  wipersnareCommandList: []
}

const getters = {
  wipersnareCommandList: state => state.wipersnareCommandList
}

const actions = {
  async addWipersnareCommand(context, payload) {
    NProgress.start();
    let agentId = payload.agent_id;
    let url;
    if (agentId != null) {
      url = `/wipersnare/command/${agentId}`
      delete payload.agent_id;
    } else {
      url = `/wipersnare/command/organization`
    }
    let { data } = await axios.post(url, payload)
    if (!data.success) {
        context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
    } else {
        context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
        NProgress.done();
    }
  },
}

const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
