import getPusherClient from '@/pusher';
import { DeepstreamClient } from '@deepstream/client';


class MessagingService {
    constructor() {
        if (process.env.VUE_APP_ONPREM) {
            this.client = new DeepstreamClient(process.env.VUE_APP_DEEPSTREAM_ENDPOINT)
            this.clientType = "deepstream"
        } else {
            this.client = getPusherClient()
            this.clientType = "pusher"
        }
    }

    removeAllListeners() {
        if (process.env.VUE_APP_ONPREM) {
            for (const channelId in this.client?.event?.emitter?.callbacks) {
                this.client.event.unsubscribe(channelId)
            }
        } else {
            for (const channelId in this.client.channels.channels) {
                this.client.unsubscribe(channelId)
            }
        }
    }

    setListeners(context, orgId, objCreateCallback, objUpdateCallback) {
        if (process.env.VUE_APP_ONPREM) {
            this.client.login()
            this.client.event.subscribe(`${orgId}/create`, (data) => objCreateCallback(context, data))
            this.client.event.subscribe(`${orgId}/update`, (data) => objUpdateCallback(context, data))
        } else {
            const channel = this.client.subscribe(orgId)
            channel.bind('create', (data) => objCreateCallback(context, data))
            channel.bind('update', (data) => objUpdateCallback(context ,data))
        }
    }
}


export default MessagingService;
