<template>
  <v-avatar color="#38BD53" :size="size" class="my-3 ms-2 pa-5">
    <img v-if="userProfileImage" :src="userProfileImage" />
    <span v-else class="white--text text-h5">{{ initials }}</span>
  </v-avatar>
</template>

<script>
import localService from '../../services/localService';

export default {
  props: {
    size: {
      type: String,
      default: () => '90'
    }
  },
  computed: {
    userDetails() {
      return localService.getItem('ransomsnare-userInfo');
    },
    initials() {
      if (this.userDetails) {
        const splitName = this.userDetails.full_name.split(' ');
        return splitName[0].charAt(0).toUpperCase() + splitName[1].charAt(0).toUpperCase();
      }
    },
    userProfileImage() {
      if (this.userDetails.profileImage && this.userDetails.profileImage.length > 0)
        return this.userDetails.profileImage;
      return null;
    }
  }
}
</script>

<style>

</style>