import NProgress from 'nprogress';
import axios from '@/plugins/axios';


const state = {
  auditLogs: [],
  auditLogsCount: 0,
  loading: false,
  dateFilter: null
}

const getters = {
  auditLogs: state => state.auditLogs,
  auditLogsCount: state => state.auditLogsCount,
  dateFilter: state => state.dateFilter,
  auditLogsLoading: state => state.loading
}

const actions = {
  async getAuditLogs(context, payload) {

    context.commit('SET_LOADING', true)
    context.commit('SET_AUDIT_LOGS', [])
    let query = {}
    if (payload?.timestamp) {
      payload.timestamp.sort((a, b) => new Date(a) - new Date(b));
      query['start'] = payload.timestamp[0];
      query['end'] = payload.timestamp[1];
    }
    if (payload?.pageNo)
      query['page'] = payload.pageNo
    if (payload?.perPage)
      query['per_page'] = payload.perPage
    
    // const organizationId = context.rootGetters.currentOrganization.entity_id;
    return axios.get(`/audit_logs`, {
        params: query
    }).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
      } else {
        context.commit('SET_AUDIT_LOGS', resData.data);
      }
      context.commit('SET_LOADING', false)
    })
  },
  async getAuditLogsCount(context, payload) { 
    let query = {}
    if (payload?.timestamp) {
      payload.timestamp.sort((a, b) => new Date(a) - new Date(b));
      query['start'] = payload.timestamp[0];
      query['end'] = payload.timestamp[1];
    }

    return axios.get(`/audit_logs/count`, {
        params: query
    }).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
      } else {
        context.commit('SET_AUDIT_LOGS_COUNT', resData.data);
      }
    })
  },
  async getLatestAuditLogs(context) {
    context.commit('SET_LOADING', true)
    context.commit('SET_AUDIT_LOGS', [])
    return axios.get(`/audit_logs/latest`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
      } else {
        context.commit('SET_AUDIT_LOGS', resData.data);
      }
      context.commit('SET_LOADING', false)
    })
  }
}

const mutations = {
  SET_AUDIT_LOGS(state, data) {
    state.auditLogs = data;
  },
  SET_AUDIT_LOGS_COUNT(state, data) {
    state.auditLogsCount = data;
  },
  SET_LOADING(state, loading) {
    loading ? NProgress.start() : NProgress.done();
    state.loading = loading;
  },
  SET_DATE_FILTER(state, filter) {
    state.dateFilter = filter;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
