import Pusher from 'pusher-js'
// Enable pusher logging - don't include this in production
Pusher.logToConsole = process.env.VUE_APP_ENV === 'dev'

function getPusherClient() {
  return new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: true
  })
}

export default getPusherClient
