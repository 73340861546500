<template>
  <v-select
    v-model="selectedValue"
    :items="items" 
    :label="label" 
    color="#172950"
    :menu-props="{ bottom: true, offsetY: true }"
    outlined
    hide-details
    dense 
    item-text="name"
    item-value="entity_id"
    @change="handleSelectedItem"
  >
    <template v-slot:item="{ active, item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-content>
          <v-list-item-title>
            <v-row no-gutters align="center">
            <span>{{ item.name }}</span>
            <v-spacer></v-spacer>
            <v-chip v-if="item.is_managed" text-color="black" small>
              <template v-if="getOrganizationName(item.managed_by)">
                {{ getOrganizationName(item.managed_by) }}
              </template>
              <template v-else>
                Managed
              </template>
            </v-chip>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => 'label here'
    },
    defaultValue: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    selectedValue: null
  }),
  watch: {
    defaultValue() {
      if(this.defaultValue) {
        this.selectedValue = this.defaultValue
      }
    }
  },
  methods: {
    handleSelectedItem(item) {
      this.$emit('selected-item', item);
    },
    setDefault() {
      if(this.defaultValue) {
        this.selectedValue = this.defaultValue
      }
    },
    getOrganizationName(orgId) {
      if (this.items) {
        let item = this.items.find((e) => e.entity_id == orgId);
        return item ? item.name : null;
      }
      return null;
    }
  },
  mounted() {
    this.setDefault();
  }
}
</script>