import NProgress from 'nprogress'
import axios from '@/plugins/axios'

const state = {
  crashLogsLoading: false,
  crashLogsList: [],
  agentId: null
}

const getters = {
  crashLogsList: state => {
    return state.crashLogsList
  },
  crashLogsLoading: state => {
    return state.crashLogsLoading
  }
}

const actions = {
  async getCrashLogs(context, agentId) {
    NProgress.start();
    context.commit('SET_CRASH_LOGS_LIST', { data: [], agentId: null })
    let url = `/crash_logs`
    if (agentId) {
      url += '/' + agentId
    }
    return axios.get(url).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_CRASH_LOGS_LIST', { data: resData.data, agentId: agentId })
        context.commit('SET_SEEN_ALL_CRASH_LOGS', agentId, { root: true })
      }
    })
  }
}

const mutations = {
  SET_CRASH_LOGS_LIST(state, { data, agentId }) {
    state.crashLogsList = data
    state.agentId = agentId
    NProgress.done()
  },
  ADD_CRASH_LOG(state, data) {
    if (state.agentId == data.agent_id || state.agentId == null) {
      state.crashLogsList.unshift(data)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
