var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"mt-6 py-2 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none",class:_vm.navbarFixed
      ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
      : '',attrs:{"color":_vm.background,"height":"auto","flat":""}},[_c('v-row',{staticClass:"py-1"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"6"}},[(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"drawer-toggler pa-5 pl-3 cursor-pointer",class:{ active: _vm.togglerActive },on:{"click":_vm.minifyDrawer}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line bg-body"}),_c('i',{staticClass:"drawer-toggler-line bg-body"}),_c('i',{staticClass:"drawer-toggler-line bg-body"})])]):_vm._e(),(_vm.organizationList.length > 1)?_c('div',[_c('app-dropdown',{attrs:{"label":'Organization',"items":_vm.sortedOrganizationList,"defaultValue":_vm.currentOrganization},on:{"selected-item":_vm.changeSelectedOrganization}})],1):_c('div',{staticClass:"organization-dropdown"},[_c('v-text-field',{attrs:{"value":_vm.currentOrganization.name,"disabled":""}})],1)]),_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.rtl ? 'text-sm-left' : 'text-sm-right',attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-inline-block ms-auto pr-5"},[_c('small',[_vm._v("v")]),_vm._v(_vm._s(_vm.appVersion))]),(_vm.$vuetify.breakpoint.mobile)?_c('v-btn',{staticClass:"\n          font-weight-600\n          text-capitalize\n          drawer-toggler\n          py-3\n          px-0\n          rounded-sm\n        ",class:{
          'btn-dark-toggler-hover': !_vm.hasBg,
          'btn-toggler-hover': _vm.hasBg,
          active: _vm.togglerActive,
        },attrs:{"elevation":"0","ripple":false,"height":"43","color":"transparent"},on:{"click":_vm.drawerClose}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line text-body"}),_c('i',{staticClass:"drawer-toggler-line text-body"}),_c('i',{staticClass:"drawer-toggler-line text-body"})])]):_vm._e(),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","offset-x":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-body",class:{ 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },attrs:{"icon":"","ripple":false,"color":_vm.linkColor,"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"material-icons-round",attrs:{"size":"20"}},[_vm._v(" account_circle ")])],1)]}}])},[_c('v-list',{staticClass:"pa-3"},_vm._l((_vm.accountMenu),function(item,i){return _c('v-list-item',{key:i,staticClass:"\n              pa-4\n              list-item-hover-active\n              d-flex\n              align-items-center\n              py-1\n              my-1\n              border-radius-md\n            ",on:{"click":function($event){return _vm.handleItemClick(item)}}},[_c('v-icon',{staticClass:"material-icons-round text-body",attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-body-2 ls-0 text-typo font-weight-600 mb-0"},[_c('v-row',[_c('v-col',[_c('h6',{staticClass:"text-sm font-weight-normal ms-2 text-typo"},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)],1)],1)],1)}),1)],1),(_vm.show_future)?_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","offset-x":"","min-width":"300","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-body",class:{ 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },attrs:{"icon":"","ripple":false,"color":_vm.linkColor,"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"material-icons-round",attrs:{"size":"20"}},[_vm._v(" notifications ")]),_c('v-badge',{staticClass:"position-absolute",attrs:{"color":"#f44335","content":_vm.notifications.length,"offset-x":"1","offset-y":"0","bordered":""}})],1)]}}],null,false,921388685)},[_c('v-list',{staticClass:"pa-3"},_vm._l((_vm.notifications),function(item,i){return _c('v-list-item',{key:i,staticClass:"\n              pa-4\n              list-item-hover-active\n              d-flex\n              align-items-center\n              py-1\n              my-1\n              border-radius-md\n            "},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-body-2 ls-0 text-typo font-weight-600 mb-0"},[_c('v-row',[_c('v-col',[_c('h6',{staticClass:"text-sm font-weight-normal ms-2 text-typo"},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)],1)],1)],1)}),1)],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.changePasswordDialog),callback:function ($$v) {_vm.changePasswordDialog=$$v},expression:"changePasswordDialog"}},[_c('change-password-card',{on:{"cancel":function($event){_vm.changePasswordDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }