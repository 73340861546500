import NProgress from 'nprogress';
import axios from '@/plugins/axios';
import { v4 as uuidv4 } from 'uuid';

const state = {
  wipersnareMetricsData: {},
  wipersnareMetricsLoading: false,
  wipersnareMetricsMounted: false,
  wipersnareAgentMetricsAlerts: {},
  wipersnareAgentMetricsAlertsLoading: false
}

const getters = {
  wipersnareMetricsData: state => state.wipersnareMetricsData,
  wipersnareMetricsLoading: state => state.wipersnareMetricsLoading,
  wipersnareAgentMetricsAlerts: state => state.wipersnareAgentMetricsAlerts,
  wipersnareAgentMetricsAlertsLoading: state => state.wipersnareAgentMetricsAlertsLoading
}

const actions = {
  async loadWipersnareMetrics(context, payload) {
    NProgress.start();
    let url = `/wipersnare/performance-metrics`
    let { data } = await axios.get(url)
    context.commit('SET_WIPERSNARE_METRICS_LOADING', true)
    if (!data.success) {
        context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
        NProgress.done();
    } else {
        context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
        context.commit('SET_WIPERSNARE_METRICS_DATA', data.data);
    }
    context.commit('SET_WIPERSNARE_METRICS_LOADING', false);
  },
  async loadWipersnareAgentMetricsAlerts(context, payload) {
    context.commit('SET_WIPERSNARE_AGENT_METRICS_ALERTS', {});
    NProgress.start()
    let url = `/wipersnare/performance-metrics/` + payload.agent_id + `?is_alert=1`
    context.commit('SET_WIPERSNARE_AGENT_METRICS_ALERTS_LOADING', true)

    let { data } = await axios.get(url)
    if (!data.success) {
      context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      NProgress.done();
    } else {
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      context.commit('SET_WIPERSNARE_AGENT_METRICS_ALERTS', data.data);
    }
    context.commit('SET_WIPERSNARE_AGENT_METRICS_ALERTS_LOADING', false);
  }
}

const mutations = {
    SET_WIPERSNARE_METRICS_DATA(state, data) {
        state.wipersnareMetricsData = data
        state.wipersnareMetricsLoading = false
        state.wipersnareMetricsMounted = true
        NProgress.done()
    },
    SET_WIPERSNARE_AGENT_METRICS_ALERTS(state, data) {
      state.wipersnareAgentMetricsAlerts = data
      state.wipersnareMetricsLoading = false
      state.wipersnareMetricsMounted = true
      NProgress.done()
    },
    SET_WIPERSNARE_AGENT_METRICS_ALERTS_LOADING(state, data) {
      state.wipersnareAgentMetricsAlertsLoading = data
    },
    SET_WIPERSNARE_METRICS_LOADING(state, data) {
        state.wipersnareMetricsLoading = data
    },
    SET_WIPERSNARE_METRICS_MOUNTED(state, data) {
        state.wipersnareMetricsMounted = data
    },
    ADD_WIPERSNARE_METRICS(state, data) {
        state.wipersnareMetricsData[data.agent_id].push(data)
    }
}

export default {
  state,
  getters,
  actions,
  mutations
}
