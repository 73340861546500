<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="mt-6 py-2 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat
  >
    <v-row class="py-1">
      <v-col cols="12" sm="6" class="d-flex align-center">
        <div
          class="drawer-toggler pa-5 pl-3 cursor-pointer"
          :class="{ active: togglerActive }"
          @click="minifyDrawer"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
          </div>
        </div>
        <div v-if="organizationList.length > 1">
          <app-dropdown :label="'Organization'" :items="sortedOrganizationList" @selected-item="changeSelectedOrganization" :defaultValue="currentOrganization" />
        </div>
        <div class="organization-dropdown" v-else>
          <v-text-field
            :value="currentOrganization.name"
            disabled
          >
          </v-text-field>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="d-flex align-center"
        :class="$vuetify.rtl ? 'text-sm-left' : 'text-sm-right'"
      >
        <div class="d-inline-block ms-auto pr-5"><small>v</small>{{ appVersion }}</div>
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-600
            text-capitalize
            drawer-toggler
            py-3
            px-0
            rounded-sm
          "
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
          </div>
        </v-btn>

        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="150"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-body"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon class="material-icons-round" size="20">
                account_circle
              </v-icon>
            </v-btn>
          </template>

          <v-list class="pa-3">
            <v-list-item
              v-for="(item, i) in accountMenu"
              :key="i"
              class="
                pa-4
                list-item-hover-active
                d-flex
                align-items-center
                py-1
                my-1
                border-radius-md
              "
              @click="handleItemClick(item)"
            >
              <v-icon class="material-icons-round text-body" size="20">{{
                item.icon
              }}</v-icon>

              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col>
                      <h6
                        class="text-sm font-weight-normal ms-2 text-typo"
                      >
                        {{ item.title }}
                      </h6>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="300"
          max-width="300"
          v-if="show_future"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-body"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon class="material-icons-round" size="20">
                notifications
              </v-icon>
              <v-badge
                color="#f44335"
                :content="notifications.length"
                offset-x="1"
                offset-y="0"
                class="position-absolute"
                bordered
              >
              </v-badge>
            </v-btn>
          </template>

          <v-list class="pa-3">
            <v-list-item
              v-for="(item, i) in notifications"
              :key="i"
              class="
                pa-4
                list-item-hover-active
                d-flex
                align-items-center
                py-1
                my-1
                border-radius-md
              "
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col>
                      <h6
                        class="text-sm font-weight-normal ms-2 text-typo"
                      >
                        {{ item.title }}
                      </h6>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-dialog v-model="changePasswordDialog" max-width="500px">
      <change-password-card @cancel="changePasswordDialog = false" />
    </v-dialog>
  </v-app-bar>
</template>
<script>
import { mapGetters } from 'vuex';
import versionInformation from '../../version';
import AppDropdown from './Base/AppDropdown';
import ChangePasswordCard from '@/components/ChangePasswordCard.vue';

var show_future = process.env.VUE_APP_SHOW_FUTURE == 'true'
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: Boolean,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  components: { AppDropdown, ChangePasswordCard },
  computed: {
    ...mapGetters(['organizationList', 'currentOrganization']),
    sortedOrganizationList() {
      return this.organizationList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
    },
    appVersion() {
      return versionInformation['version'];
    }
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
      changePasswordDialog: false,
      accountMenu: [
        {
          icon: "enhanced_encryption",
          title: "MFA",
        },
        {
          icon: "lock_reset",
          title: "Update Password",
        },
        {
          icon: "logout",
          title: "Logout",
        }
      ],
      notifications: [
        {
          title: 'Test'
        }
      ],
      show_future
    };
  },
  methods: {
    handleItemClick(item) {
      switch(item.title) {
        case "Logout":
          this.$store.dispatch('login/logout');
          break;
        case "MFA":
          this.$router.push('/mfa');
          break;
        case "Update Password":
          this.changePasswordDialog = true;
          break;
      }
    },
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    changeSelectedOrganization(organizationId) {
      let organization = this.organizationList.find((e) => e.entity_id == organizationId);
      this.$store.dispatch('changeCurrentOrganization', organization);
    }
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>
<style lang="scss">
.organization-dropdown {
  .v-input--is-disabled {
    .v-input__slot {
      background: none !important;
      box-shadow: none !important;
    }
  }
  .v-input__slot {
    margin-top: 6px;
    margin-bottom: 0;
    input {
       color: #5C5E6D !important;
       font-weight: bold;
       font-size: 13px;
       padding-bottom: 2px !important;
    }

    .v-input--is-disabled input {
       opacity: 50%;
    }
  }
}
</style>
