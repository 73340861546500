import NProgress from 'nprogress';
import axios from '@/plugins/axios';
import { v4 as uuidv4 } from 'uuid';

const state = {
  metricsData: {},
  metricsLoading: false,
  metricsMounted: false,
  agentMetricsAlerts: {},
  agentMetricsAlertsLoading: false
}

const getters = {
  metricsData: state => state.metricsData,
  metricsLoading: state => state.metricsLoading,
  agentMetricsAlerts: state => state.agentMetricsAlerts,
  agentMetricsAlertsLoading: state => state.agentMetricsAlertsLoading
}

const actions = {
  async loadMetrics(context, payload) {
    NProgress.start();
    let url = `/performance-metrics`
    let { data } = await axios.get(url)
    context.commit('SET_METRICS_LOADING', true)
    if (!data.success) {
        context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
        NProgress.done();
    } else {
        context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
        context.commit('SET_METRICS_DATA', data.data);
    }
    context.commit('SET_METRICS_LOADING', false);
  },
  async loadAgentMetricsAlerts(context, payload) {
    context.commit('SET_AGENT_METRICS_ALERTS', {});
    NProgress.start()
    let url = `/performance-metrics/` + payload.agent_id + `?is_alert=1`
    context.commit('SET_AGENT_METRICS_ALERTS_LOADING', true)

    let { data } = await axios.get(url)
    if (!data.success) {
      context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      NProgress.done();
    } else {
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      context.commit('SET_AGENT_METRICS_ALERTS', data.data);
    }
    context.commit('SET_AGENT_METRICS_ALERTS_LOADING', false);
  }
}

const mutations = {
    SET_METRICS_DATA(state, data) {
        state.metricsData = data
        state.metricsLoading = false
        state.metricsMounted = true
        NProgress.done()
    },
    SET_AGENT_METRICS_ALERTS(state, data) {
      state.agentMetricsAlerts = data
      state.metricsLoading = false
      state.metricsMounted = true
      NProgress.done()
    },
    SET_AGENT_METRICS_ALERTS_LOADING(state, data) {
      state.agentMetricsAlertsLoading = data
    },
    SET_METRICS_LOADING(state, data) {
        state.metricsLoading = data
    },
    SET_METRICS_MOUNTED(state, data) {
        state.metricsMounted = data
    },
    ADD_METRICS(state, data) {
        state.metricsData[data.agent_id].push(data)
    }
}

export default {
  state,
  getters,
  actions,
  mutations
}
