import NProgress from 'nprogress'
import axios from '@/plugins/axios'
import { vm } from '../main';

const state = {
  mfaBarCodeImage: null,
  recoveryCodes: null,
}

const getters = {
    mfaBarCodeImage: state => {
      return state.mfaBarCodeImage
    },
    recoveryCodes: state => {
      return state.recoveryCodes
    },
}

const actions = {
  async getMFABarCodeImage (context, payload) {
    NProgress.start()
    return axios.post('/qrcode', {
      password: payload.password,
      login_type: payload.login_type
    }).then(function (response) {
      const resData = response.data
      if (resData && !resData.success) {
        context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
      } else {
        context.commit('SET_MFA_BAR_CODE_IMAGE', resData)
      }
    })
  },
  async verifyMFASetupOtp(context, payload) {
    NProgress.start()
    return new Promise((resolve) => {
      axios.post('/verify_otp', {
        otp: payload.otp
      }).then(function (response) {
        const resData = response.data
        if (resData && !resData.success) {
          context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
        } else {
          context.commit('SET_RECOVERY_CODES', {codes: resData.data.codes})
          resolve(response)
        }
      })
    })
  },
  async setupMFA (context, payload) {
    NProgress.start()
    let requestData = {
      mfa_enabled: payload.mfa_enabled,
      otp: payload.otp
    };
    // Include the password in the request only if it's provided
    if (payload.password) {
      requestData.password = payload.password;
    }
    return new Promise((resolve) => {
      axios.post('/setup_mfa', requestData).then(function (response) {
        const resData = response.data
        if (resData && !resData.success) {
          context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
        } else {
          let currentUser = JSON.parse(JSON.stringify(context.rootState.login.currentUser))
          currentUser.mfa_enabled = payload.mfa_enabled
          currentUser.mfa_verified = true
          context.commit('login/setState', {key: 'currentUser', value: currentUser})
          context.commit('SETUP_MFA_USER', {mfa_enabled: payload.mfa_enabled})
          resolve(response)
        }
      })
    })
  },
  // async getRecoveryCodes (context) {
  //   NProgress.start()
  //   return axios.get('/recovery_codes', {}).then(function (response) {
  //     const resData = response.data
  //     if (!resData.success) {
  //       context.commit('API_ERROR', resData.message)
  //     } else {
  //       context.commit('SET_RECOVERY_CODES', {codes: resData.data})
  //     }
  //   })
  // },
}

const mutations = {
  SET_MFA_BAR_CODE_IMAGE (state, data) {
    state.mfaBarCodeImage = data.image
    NProgress.done()
  },
  SET_RECOVERY_CODES (state, data) {
    state.recoveryCodes = data.codes
    NProgress.done()
  },
  SETUP_MFA_USER (state, data) {
    let user = JSON.parse(localStorage.getItem('ransomsnare-userInfo'))
    user['mfa_enabled'] = data.mfa_enabled
    user['mfa_verified'] = true
    localStorage.setItem('ransomsnare-userInfo', JSON.stringify(user))
    let status = data.mfa_enabled ? 'activated' : 'deactivated'
    vm.$notify({
      group: 'messages',
      type: 'success',
      text: `Multi-factor Authentication has been ${status} successfully`
    })
    NProgress.done()
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
