<template>
  <v-card class="card-shadow border-radius-xl">
    <div class="card-header-padding card-border-bottom">
      <span class="font-weight-bold text-h5 text-typo mb-0">Change Password</span>
    </div>
    <v-card-text class="card-padding pb-2">
      <v-container class="px-0">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" class="py-0">
              <label class="label-text-14">Current Password</label>
              <v-text-field
                v-model="currentPassword"
                outlined
                type="password"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mb-0
                "
                dense
                flat
                validate-on-blur
                :rules="[
                  v => !!v || 'Current password is required.'
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <label class="label-text-14">New Password</label>
              <v-text-field
                v-model="newPassword"
                outlined
                type="password"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mb-0
                "
                :rules="passwordRules"
                dense
                validate-on-blur
                flat
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <label class="label-text-14">Confirm New Password</label>
              <v-text-field
                v-model="newPassword2"
                outlined
                type="password"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mb-0
                "
                dense
                flat
                validate-on-blur
                :rules="[
                  v => v === newPassword || 'Passwords do not match.'
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions class="card-padding d-flex justify-end pt-0">
      <v-btn
        @click="cancel"
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-normal
          text-capitalize
          btn-ls btn-outline-secondary
          bg-transparent
          py-3
          px-6
        "
        >Cancel</v-btn
      >

      <v-btn
        @click="save"
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-normal
          text-capitalize
          btn-ls btn-primary
          bg-gradient-primary
          py-3
          px-6
        "
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      valid: false,
      currentPassword: '',
      newPassword: '',
      newPassword2: '',
      passwordRules: [
        v => !!v || 'New password is required',
        v => v?.length >= 8 || 'Min 8 characters',
      ],
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('auth/updatePassword', {
          current_password: this.currentPassword,
          new_password: this.newPassword
        })
        this.$emit('cancel')
        this.$refs.form.reset()
      }
    }
  }
}
</script>