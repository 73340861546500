import NProgress from 'nprogress'
import axios from '@/plugins/axios'

const state = {
  wipersnareAlerts: [],
  wipersnareAlertsCount: null,
  wipersnareAlertsLoading: false
}

const getters = {
  wipersnareAlerts: state => {
    return state.wipersnareAlerts
  },
  wipersnareAlertsCount: state => {
    return state.wipersnareAlertsCount
  },
  wipersnareAlertsLoading: state => {
    return state.wipersnareAlertsLoading
  }
}

const actions = {
  async getWipersnareAlerts(context, payload) {
    NProgress.start();
    context.commit('SET_LOADING', true)
    let url = `/wipersnare/alerts/paginated?`
    if (payload.dismissed) {
      url += `dismissed=true&`
    }
    if (payload.pageNo) {
      url += `page=${payload.pageNo}&`
    }
    if (payload.perPage) {
      url += `per_page=${payload.perPage}`
    }
    return axios.get(url).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_WIPERSNARE_ALERT_LIST', {data: resData.data, page: payload.pageNo})
      }
      context.commit('SET_LOADING', false)
    })
  },
  async getWipersnareAlertsCount(context, payload) {
    let url = `/wipersnare/alerts/count`
    if (payload?.dismissed) {
      url += `?dismissed=true`
    }
    return axios.get(url).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_WIPERSNARE_ALERTS_COUNT', resData.data)
      }
    })
  },
  async getDismissedWipersnareAlerts(context) {
    NProgress.start();
    context.commit('SET_LOADING', true)
    return axios.get(`/wipersnare/alerts?dismissed=true`).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_WIPERSNARE_ALERT_LIST', resData.data)
      }
      context.commit('SET_LOADING', false)
    })
  },
  resetWipersnareAlertsList(context) {
    context.commit('RESET_WIPERSNARE_ALERT_LIST')
  },
  async dismissWipersnareAlert(context, payload) {
    NProgress.start();
    return axios.post(`/wipersnare/alerts/dismiss`, payload).then(function (response) {
      const resData = response.data
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true })
      } else {
        context.commit('SET_WIPERSNARE_ALERT_LIST', context.state.alerts.filter((alert) => {
          return alert.entity_id != payload.entity_id
        }))
      }
    })
  }
}

const mutations = {
  RESET_WIPERSNARE_ALERT_LIST(state) {
    state.wipersnareAlerts = []
  },
  SET_WIPERSNARE_ALERT_LIST(state, data) {
    state.wipersnareAlerts = data.data
    NProgress.done()
  },
  SET_WIPERSNARE_ALERTS_COUNT(state, data) {
    state.wipersnareAlertsCount = data.total
    NProgress.done()
  },
  SET_LOADING(state, isLoading) {
    state.wipersnareAlertsLoading = isLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
