import NProgress from 'nprogress'
import axios from '@/plugins/axios';
import { v4 as uuidv4 } from 'uuid'

const state = {
  userList: []
}

const getters = {
  userList: state => {
    return state.userList
  }
}

const actions = {
  async getUsers(context) {
    NProgress.start();
    const organizationId = context.rootGetters.currentOrganization.entity_id;
    return axios.get(`/organization/${organizationId}/user`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch('showMessage', { ...resData, messageType: 'error', messageTitle: 'Error' }, { root: true });
      } else {
        context.commit('SET_USER_LIST', resData.data)
      }
    })
  },
  async addUser(context, payload) {
    NProgress.start()
    const organizationId = context.rootGetters.currentOrganization.entity_id;
    let user = Object.assign({}, payload)
    user.tempId = 'USER_'+uuidv4()
    context.commit('ADD_USER', user)
    try {
      const { data } = await axios.post(`/organization/${organizationId}/user`, { first_name: payload.first_name, last_name: payload.last_name, email: payload.email });
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      context.commit('UPDATE_ADDED_USER', {user: data.data, tempId: user.tempId})
      return true;
    } catch(e) {
      console.log(e);
    }
  },
  async deleteUser(context, payload) {
    NProgress.start()
    const organizationId = context.rootGetters.currentOrganization.entity_id;
    context.commit('DELETE_USER', payload);
    try {
      const { data } = await axios.delete(`/organization/${organizationId}/user`, { data: { 'user_id': payload.entity_id } });
      if (!data.success) {
        return context.dispatch('showMessage', { ...data, messageType: 'error', messageTitle: 'Error' }, { root: true });
      }
      context.dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return true;
    } catch(e) {
      console.log(e);
    }
  },
}

const mutations = {
  SET_USER_LIST(state, data) {
    state.userList = data
    NProgress.done()
  },
  ADD_USER(state, data) {
    state.userList.push(data)
    NProgress.done()
  },
  DELETE_USER(state, payload) {
    let index = state.userList.findIndex(e => e.entity_id == payload.entity_id)
    if(index > -1) {
      state.userList.splice(index, 1)
    }
    NProgress.done()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
