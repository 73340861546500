<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="position-relative min-vh-100"
        :style="`background-color:  #212338`"
      >
        <span class="mask bg-gradient-default opacity-6"></span>
        <v-container class="my-auto">
          <v-row class="min-vh-100">
            <fade-transition :duration="200" origin="center top" mode="out-in">
              <v-container class="pb-0 position-relative">
                <v-img
                  src="@/assets/ransomsnare-logo.png"
                  class="navbar-brand-img auth-logo"
                  width="300"
                >
                </v-img>
                <p class="white--text version-info"><small>v</small>{{ appVersion }}</p>
                <router-view></router-view>
              </v-container>
            </fade-transition>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import versionInformation from '../../version.js';

export default {
  name: "auth-page-layout",
  components: {
    FadeTransition
  },
  computed: {
    appVersion() {
      return versionInformation['version'];
    }
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style scoped>
  .version-info {
    position: absolute;
    right: 10px;
  }
</style>