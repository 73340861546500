import axios from 'axios';
import localService from '../services/localService';
import NProgress from 'nprogress';

const GET_API_URL = () => {
  return process.env.VUE_APP_API_URL;
}

const options = {
  baseURL: GET_API_URL(),
  headers: {
    'Content-Type': 'application/json',
  }
};

const instance = axios.create({ ...options });

instance.interceptors.request.use((config) => {
  const token = localService.getItem('ransomsnare-token');
  const organization = localService.getItem('ransomsnare-currentOrganization');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if(organization) {
    config.headers['x-organization-id'] = organization.entity_id;
  }
  return config;
});

instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if(error && error.response && error.response.status === 401) {
    setTimeout(() => {
      localService.removeItem('ransomsnare-token');
      localService.removeItem('ransomsnare-token-expiry');
      localService.removeItem('ransomsnare-userInfo');
      localService.removeItem('ransomsnare-loginType');
      localService.removeItem('ransomsnare-currentOrganization');
      window.location = '/login';
    }, 1000)
  }
  NProgress.done()
  return Promise.reject(error);
});

export default instance;